import { Component, OnInit, DoCheck } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { SharedService } from './shared/sharedServices/shared.service';
import { ApiService } from './shared/sharedServices/api.service';

@Component({
  selector: 'body',
  templateUrl: './app.component.html',
  styles: ["./app.component.css"]
})
export class AppComponent implements OnInit {
  public isLoader: boolean = false
  constructor(private router: Router, private loader: ApiService) { }

  ngOnInit() {
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0);
      this.loader.getLoader().subscribe(res => {
         setTimeout(() => this.isLoader = res);
       });
    });
  }
}
