import {Component} from '@angular/core';
import { navItems } from '../../_nav';
import { Router } from '@angular/router';
import { AuthService} from '../../services/auth.service';
@Component({
  selector: 'app-dashboard',
  templateUrl: './default-layout.component.html'
})
export class DefaultLayoutComponent {
  public sidebarMinimized = false;
  public navItems = navItems;
  show: boolean = false;
  constructor( private route: Router,private authService: AuthService){}
  toggleMinimize(e) {
    this.sidebarMinimized = e;
  }

  logOut(){
    this.authService.signOut()
  }
  showModal(){
    this.show = !this.show;
  }

}
