import { Component, OnInit } from '@angular/core';
import { Router,ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthService} from '../../services/auth.service';
import {PasswordValidation} from '../../shared/sharedServices/customeValidation';
@Component({
  selector: 'app-dashboard',
  templateUrl: './reser-password.component.html',
  styleUrls: ['./reser-password.component.css']
})
export class ReserPasswordComponent implements OnInit {
  resetForm: FormGroup;
  isSubmitted: boolean;
  isShowError: boolean;
  errMsg: String;
   OTP:any;
   isShow : boolean;
   successMsg : String;
  constructor(private fb: FormBuilder, private route: Router, private authService: AuthService,
    private navParams: ActivatedRoute) {
    this.isSubmitted = false;
    this.isShowError = false;
    this.errMsg = "";
    this.successMsg = "";
    this.isShow = false

    this.navParams.params.subscribe(param => {
      this.OTP = param['otp'];
      console.log( this.OTP)
    });
   }

  ngOnInit(){
    this.formBuilder();
  }
  formBuilder(): void {
    this.resetForm = this.fb.group({
      password: ['', [Validators.required,Validators.pattern(/^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/)]],
      confpassword: ['',Validators.required]
    }, {validators: PasswordValidation.MatchPassword});
  }
  submitForm(){
       this.isSubmitted =true;
       let obj = {
        token:this.OTP,
        password:this.resetForm.value.password
       }
       console.log(this.resetForm.status)
    if(this.resetForm.status === 'VALID') {
      console.log(this.resetForm.value)
      this.authService.resetPassword(obj).subscribe(res => {
        console.log(res)
        this.isSubmitted = false;
        this.isShow = true;
        this.successMsg = res.data
       
            
      }, error => {
          console.log(error);
          this.isShowError = true;
          this.errMsg = error.error.message;
          setTimeout(() => {
            this.isShowError = false;
          }, 3000);
      });

    }
  }
  dismissModal(e): void {
    this.isShow = false;
    this.route.navigate(['/adminUrl/login']);
  } 

}
