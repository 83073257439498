import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthService} from '../../services/auth.service';
@Component({
  selector: 'app-dashboard',
  templateUrl: 'login.component.html'
})
export class LoginComponent implements OnInit{ 
  loginForm: FormGroup;
  isSubmitted: boolean;
  isShowError: boolean;
  errMsg: String;
  constructor(private fb: FormBuilder, private route: Router, private authService: AuthService){
    this.isSubmitted = false;
    this.isShowError = false;
    this.errMsg = "";
  }

  ngOnInit(){
    this.formBuilder();
  }
  formBuilder(): void {
    this.loginForm = this.fb.group({
      email: ['', Validators.required],
      password: ['', Validators.required]
    });
  }
  submitForm(){
       this.isSubmitted =true;
    if(this.loginForm.status === 'VALID') {
       this.authService.login(this.loginForm.value).subscribe(res => {
  
        this.isSubmitted = false;
         this.route.navigate(['/dashboard']);
            
      }, error => {
          console.log(error);
          this.isShowError = true;
          this.errMsg = error.error.message;
          setTimeout(() => {
            this.isShowError = false;
          }, 3000);
      });

    }
  }
}
