import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
// Import Containers
import { DefaultLayoutComponent } from './containers';
import { P404Component } from './views/error/404.component';
import { P500Component } from './views/error/500.component';
import { LoginComponent } from './views/login/login.component';
import { RegisterComponent } from './views/register/register.component';
import { ForgotPasswordComponent } from './views/forgot-password/forgot-password.component';
import { ReserPasswordComponent } from './views/reser-password/reser-password.component';
import { SuccessMsgComponent } from './views/success-msg/success-msg.component';
import {AuthgaurdService} from './gaurd/authgaurd.service'
import {LoginService} from './gaurd/login.service'
export const routes: Routes = [
  {
    path: 'adminUrl',
    redirectTo: 'adminUrl/login',
    pathMatch: 'full',
  },
  {
    path: '404',
    component: P404Component,
    data: {
      title: 'Page 404'
    }
  },
  {
    path: '500',
    component: P500Component,
    data: {
      title: 'Page 500'
    }
  },
  {
    path: 'adminUrl/login',
    canActivate:[LoginService],
    component: LoginComponent,
    data: {
      title: 'Login Page'
    }
  },
  {
    path: 'adminUrl/forgot',
   
    component: ForgotPasswordComponent,
    data: {
      title: 'Login Page'
    }
  },
  {
    path: 'adminUrl/resetPassword/:otp',

    component: ReserPasswordComponent,
    data: {
      title: 'Login Page'
    }
  },
  {
    path: 'adminUrl/success',
 
    component: SuccessMsgComponent,
    data: {
      title: 'Login Page'
    }
  },
  {
    path: 'adminUrl/register',
    component: RegisterComponent,
    data: {
      title: 'Register Page'
    }
  },
  {
    path: '',
    component: DefaultLayoutComponent,
    // data: {
    //   title: 'Home'
    // },
    children: [
   
      {
        path: '',
        canActivate:[AuthgaurdService],
        loadChildren: () => import('./pages/pages.module').then(m => m.PagesModule)
      },
      // {
      //   path: 'base',
      //   loadChildren: () => import('./views/base/base.module').then(m => m.BaseModule)
      // },
      // {
      //   path: 'buttons',
      //   loadChildren: () => import('./views/buttons/buttons.module').then(m => m.ButtonsModule)
      // },
      // {
      //   path: 'charts',
      //   loadChildren: () => import('./views/chartjs/chartjs.module').then(m => m.ChartJSModule)
      // },
      {
        path: 'dashboard',
        redirectTo: 'adminUrl/dashboard',
        pathMatch : 'full',
        // loadChildren: () => import('./views/dashboard/dashboard.module').then(m => m.DashboardModule)
      },
      {
        path: 'adminUrl/dashboard',
        loadChildren: () => import('./views/dashboard/dashboard.module').then(m => m.DashboardModule)
      },
      // {
      //   path: 'icons',
      //   loadChildren: () => import('./views/icons/icons.module').then(m => m.IconsModule)
      // },
      // {
      //   path: 'notifications',
      //   loadChildren: () => import('./views/notifications/notifications.module').then(m => m.NotificationsModule)
      // },
      // {
      //   path: 'theme',
      //   loadChildren: () => import('./views/theme/theme.module').then(m => m.ThemeModule)
      // },
      // {
      //   path: 'widgets',
      //   loadChildren: () => import('./views/widgets/widgets.module').then(m => m.WidgetsModule)
      // }
    ]
  },
  { path: '**', component: P404Component }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    onSameUrlNavigation: 'ignore',
    scrollPositionRestoration: 'enabled'
  })],
  exports: [ RouterModule ]
})
export class AppRoutingModule {}
