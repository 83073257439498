import { Injectable } from '@angular/core';
import { IapiBaseObject } from '../shared/iModels/IApiBaseObject';
import { API_KEYS } from '../shared/apiKeys/api-keys-constants';
import { ApiService } from '../shared/sharedServices/api.service';
import { map, catchError } from 'rxjs/operators';
import { Observable, Subject, throwError,BehaviorSubject } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class UserService {
  public $pageSize = new BehaviorSubject<any>(5);
  public $pageIndex = new BehaviorSubject<any>(1);
  constructor(private _api: ApiService) { }

//   setUser(): void {
//     const systemId: any = this._shared.getDataFromStorage('SYSTEMID');
//       this.getUserDetail().subscribe(res =>{
//          this._shared.setDataInStorage('lawyerIt', res.data);
//            this.$userSource.next(res.data);
//       },error =>{
//           this.signOut();
//       }) 
//  }
  loadSizeIndex(size,index){
    this.$pageSize.next(size);
    this.$pageIndex.next(index);
  }
 getPageSize(): Observable<any> {
    return this.$pageSize.asObservable();
  }
  getPageIndex(): Observable<any> {
    return this.$pageIndex.asObservable();
  }
  chkFirmSize(data) {
    const apiObj: IapiBaseObject = {
      tokenRequiredFlag: true,
      endpoint: API_KEYS.CHKFIRMSIZE,
      apiData: data,
 
    };
    return this._api.postApi(apiObj).
      pipe(map((response: any) => {
        return response;
      }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }
  addUtilities(data) {
    const apiObj: IapiBaseObject = {
      tokenRequiredFlag: true,
      endpoint: API_KEYS.ADDUTILITY,
      apiData: data,
 
    };
    return this._api.postApi(apiObj).
      pipe(map((response: any) => {
        return response;
      }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }
  getUtilites() {
    const apiObj: IapiBaseObject = {
      tokenRequiredFlag: true,
      endpoint: API_KEYS.GETUTILITY,
    
       };
    return this._api.postApi(apiObj).
      pipe(map((response: any) => {
        return response;
      }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  deleteUtilites(data) {
    const apiObj: IapiBaseObject = {
      tokenRequiredFlag: true,
      endpoint: API_KEYS.DELETEUTILITY,
      apiData: data,
       };
    return this._api.postApi(apiObj).
      pipe(map((response: any) => {
        return response;
      }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }
  deleteAdvertisementLinks(data) {
    const apiObj: IapiBaseObject = {
      tokenRequiredFlag: true,
      endpoint: API_KEYS.DELETEADVERTISELINK,
      apiData: data,
       };
    return this._api.postApi(apiObj).
      pipe(map((response: any) => {
        return response;
      }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }
  addAdvertisement(data) {
    const apiObj: IapiBaseObject = {
      tokenRequiredFlag: true,
      endpoint: API_KEYS.ADDADVERTISE,
      apiData: data,
      multipart:true
    };
    return this._api.postApi(apiObj).
      pipe(map((response: any) => {
        return response;
      }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }
  addLinksText(data) {
    const apiObj: IapiBaseObject = {
      tokenRequiredFlag: true,
      endpoint: API_KEYS.ADDTEXTLINK,
      apiData: data
    };
    return this._api.postApi(apiObj).
      pipe(map((response: any) => {
        return response;
      }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }
  listLinksText(data,pageSize, pageIndex) {
    const apiObj: IapiBaseObject = {
      tokenRequiredFlag: true,
      endpoint: API_KEYS.LINKTEXT.replace(":size", pageSize).replace(":pageNo", pageIndex),
      apiData: data
       };
    return this._api.postApi(apiObj).
      pipe(map((response: any) => {
        return response;
      }),
        catchError((error) => {
          return throwError(error);
        })
      );
  } 
  listTemplate(pageSize, pageIndex) {
    const apiObj: IapiBaseObject = {
      tokenRequiredFlag: true,
      endpoint: API_KEYS.LISTTEMPLATE.replace(":size", pageSize).replace(":pageNo", pageIndex),
       };
    return this._api.getApi(apiObj).
      pipe(map((response: any) => {
        return response;
      }),
        catchError((error) => {
          return throwError(error);
        })
      );
  } 
  viewtemplate(data) {
    const apiObj: IapiBaseObject = {
      tokenRequiredFlag: true,
      endpoint: API_KEYS.VIEWTEMPLATE,
      apiData: data
    };
    return this._api.postApi(apiObj).
      pipe(map((response: any) => {
        return response;
      }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }
  addTemplate(data) {
    const apiObj: IapiBaseObject = {
      tokenRequiredFlag: true,
      endpoint: API_KEYS.ADDTEMPLATE,
      apiData: data
    };
    return this._api.postApi(apiObj).
      pipe(map((response: any) => {
        return response;
      }),
        catchError((error) => {
          return throwError(error);
        })
      );
  } 
  editTemplate(data) {
    const apiObj: IapiBaseObject = {
      tokenRequiredFlag: true,
      endpoint: API_KEYS.EDITTEMPLATE,
      apiData: data
    };
    return this._api.postApi(apiObj).
      pipe(map((response: any) => {
        return response;
      }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }
  deleteTemplate(data) {
    const apiObj: IapiBaseObject = {
      tokenRequiredFlag: true,
      endpoint: API_KEYS.DELETETEMPLATE,
      apiData: data
    };
    return this._api.postApi(apiObj).
      pipe(map((response: any) => {
        return response;
      }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }
  getTerms(data) {
    const apiObj: IapiBaseObject = {
      tokenRequiredFlag: true,
      endpoint: API_KEYS.GETTERM,
      apiData: data
    };
    return this._api.postApi(apiObj).
      pipe(map((response: any) => {
        return response;
      }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }
  addTerms(data) {
    const apiObj: IapiBaseObject = {
      tokenRequiredFlag: true,
      endpoint: API_KEYS.ADDTERM,
      apiData: data
    };
    return this._api.postApi(apiObj).
      pipe(map((response: any) => {
        return response;
      }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }
  graphicalUserData(data) {
    const apiObj: IapiBaseObject = {
      tokenRequiredFlag: true,
      endpoint: API_KEYS.dataChat,
      apiData: data
    };

    return this._api.postApi(apiObj).
      pipe(map((response: any) => {
        return response;
      }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }
  requestList(pageSize, pageIndex) {
    const apiObj: IapiBaseObject = {
      tokenRequiredFlag: true,
      endpoint: API_KEYS.REQUEST.replace(":size", pageSize).replace(":pageNo", pageIndex)
    };

    return this._api.getApi(apiObj).
      pipe(map((response: any) => {
        return response;
      }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }
  getFirmRequestDetail(data) {
    const apiObj: IapiBaseObject = {
      tokenRequiredFlag: true,
      endpoint: API_KEYS.GETFIRMREQUESTDETAIL,
      apiData: data
    };

    return this._api.postApi(apiObj).
      pipe(map((response: any) => {
        return response;
      }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }
  filterFirmRegisterRequest(pageSize, pageIndex, data) {
    const apiObj: IapiBaseObject = {
      tokenRequiredFlag: true,
      endpoint: API_KEYS.FILTERREQUEST.replace(":size", pageSize).replace(":pageNo", pageIndex),
      apiData: data
    };

    return this._api.postApi(apiObj).
      pipe(map((response: any) => {
        return response;
      }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }
  listAllFirm(pageSize, pageIndex) {
    const apiObj: IapiBaseObject = {
      tokenRequiredFlag: true,
      endpoint: API_KEYS.FIRMLIST.replace(":size", pageSize).replace(":pageNo", pageIndex),
    };

    return this._api.getApi(apiObj).
      pipe(map((response: any) => {
        return response;
      }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }
  searchFirm(data, pageSize, pageIndex) {
    const apiObj: IapiBaseObject = {
      tokenRequiredFlag: true,
      endpoint: API_KEYS.SEARCHFIRM.replace(":size", pageSize).replace(":pageNo", pageIndex),
      apiData: data
    };

    return this._api.postApi(apiObj).
      pipe(map((response: any) => {
        return response;
      }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }
  viewFirmDetail(id) {
    const apiObj: IapiBaseObject = {
      tokenRequiredFlag: true,
      endpoint: API_KEYS.FIRMDETAIL.replace(":firmId", id),
    };

    return this._api.getApi(apiObj).
      pipe(map((response: any) => {
        return response;
      }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }
  editFirm(data) {
    const apiObj: IapiBaseObject = {
      tokenRequiredFlag: true,
      endpoint: API_KEYS.EDITFIRM,
      apiData: data
    };

    return this._api.postApi(apiObj).
      pipe(map((response: any) => {
        return response;
      }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  extendValidity(data) {
    const apiObj: IapiBaseObject = {
      tokenRequiredFlag: true,
      endpoint: API_KEYS.ExtendValidity,
      apiData: data
    };

    return this._api.postApi(apiObj).
      pipe(map((response: any) => {
        return response;
      }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  userRoleList() {
    const apiObj: IapiBaseObject = {
      tokenRequiredFlag: true,
      endpoint: API_KEYS.ROLELIST,
    };

    return this._api.getApi(apiObj).
      pipe(map((response: any) => {
        return response;
      }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }
  listAllUser(pageSize, pageIndex) {
    const apiObj: IapiBaseObject = {
      tokenRequiredFlag: true,
      endpoint: API_KEYS.AllUser.replace(":size", pageSize).replace(":pageNo", pageIndex),
    };

    return this._api.getApi(apiObj).
      pipe(map((response: any) => {
        return response;
      }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }
  searchUser(pageSize, pageIndex, data) {
    const apiObj: IapiBaseObject = {
      tokenRequiredFlag: true,
      endpoint: API_KEYS.SEARCHUSER.replace(":size", pageSize).replace(":pageNo", pageIndex),
      apiData: data
    };

    return this._api.postApi(apiObj).
      pipe(map((response: any) => {
        return response;
      }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }
  filterUser(pageSize, pageIndex, data) {
    const apiObj: IapiBaseObject = {
      tokenRequiredFlag: true,
      endpoint: API_KEYS.FILTERUSER.replace(":size", pageSize).replace(":pageNo", pageIndex),
      apiData: data
    };

    return this._api.postApi(apiObj).
      pipe(map((response: any) => {
        return response;
      }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }
  registerFirm(data) {
    const apiObj: IapiBaseObject = {
      tokenRequiredFlag: true,
      endpoint: API_KEYS.REGISTERFIRM,
      apiData: data
    };

    return this._api.postApi(apiObj).
      pipe(map((response: any) => {
        return response;
      }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }
  registerFirmUser(data) {
    const apiObj: IapiBaseObject = {
      tokenRequiredFlag: true,
      endpoint: API_KEYS.REGISTERFIRMUSER,
      apiData: data
    };

    return this._api.postApi(apiObj).
      pipe(map((response: any) => {
        return response;
      }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }
  updateFirmStatus(data) {
    const apiObj: IapiBaseObject = {
      tokenRequiredFlag: true,
      endpoint: API_KEYS.UpDATEFIRMSTATUS,
      apiData: data
    };

    return this._api.postApi(apiObj).
      pipe(map((response: any) => {
        return response;
      }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }
  viewUserDetail(id) {
    const apiObj: IapiBaseObject = {
      tokenRequiredFlag: true,
      endpoint: API_KEYS.VIEWUSER.replace(":userId", id),
    };
    return this._api.getApi(apiObj).
      pipe(map((response: any) => {
        return response;
      }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }
  editUser(data) {
    const apiObj: IapiBaseObject = {
      tokenRequiredFlag: true,
      endpoint: API_KEYS.EDITUSER,
      apiData: data
    };

    return this._api.postApi(apiObj).
      pipe(map((response: any) => {
        return response;
      }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }
  updateUserStatus(data) {
    const apiObj: IapiBaseObject = {
      tokenRequiredFlag: true,
      endpoint: API_KEYS.UPDATEUSERSTATUS,
      apiData: data
    };

    return this._api.postApi(apiObj).
      pipe(map((response: any) => {
        return response;
      }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }
  changePassword(data) {
    const apiObj: IapiBaseObject = {
      tokenRequiredFlag: true,
      endpoint: API_KEYS.adminResetPassword,
      apiData: data
    };

    return this._api.postApi(apiObj).
      pipe(map((response: any) => {
        return response;
      }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  listAllIssues(pageSize, pageIndex) {
    const apiObj: IapiBaseObject = {
      tokenRequiredFlag: true,
      endpoint: API_KEYS.ALLISSUES.replace(":size", pageSize).replace(":pageNo", pageIndex),

    };

    return this._api.getApi(apiObj).
      pipe(map((response: any) => {
        return response;
      }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }
  searchIssue(pageSize, pageIndex, data) {
    const apiObj: IapiBaseObject = {
      tokenRequiredFlag: true,
      endpoint: API_KEYS.SEARCHISSUE.replace(":size", pageSize).replace(":pageNo", pageIndex),
      apiData: data
    };

    return this._api.postApi(apiObj).
      pipe(map((response: any) => {
        return response;
      }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }
  FilterIssueStatus(pageSize, pageIndex, data) {
    const apiObj: IapiBaseObject = {
      tokenRequiredFlag: true,
      endpoint: API_KEYS.FILTERISSUE.replace(":size", pageSize).replace(":pageNo", pageIndex),
      apiData: data
    };

    return this._api.postApi(apiObj).
      pipe(map((response: any) => {
        return response;
      }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }
  updateIssueStatus(data) {
    const apiObj: IapiBaseObject = {
      tokenRequiredFlag: true,
      endpoint: API_KEYS.UpDATEISSUESTATUS,
      apiData: data
    };

    return this._api.postApi(apiObj).
      pipe(map((response: any) => {
        return response;
      }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  listAllPlan(pageSize, pageIndex) {
    const apiObj: IapiBaseObject = {
      tokenRequiredFlag: true,
      endpoint: API_KEYS.PLANLIST.replace(":size", pageSize).replace(":pageNo", pageIndex),
    };
    return this._api.getApi(apiObj).
      pipe(map((response: any) => {
        return response;
      }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }
  addPlan(data) {
    const apiObj: IapiBaseObject = {
      tokenRequiredFlag: true,
      endpoint: API_KEYS.ADDPLAN,
      apiData: data
    };

    return this._api.postApi(apiObj).
      pipe(map((response: any) => {
        return response;
      }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }
  viewPlan(data) {
    const apiObj: IapiBaseObject = {
      tokenRequiredFlag: true,
      endpoint: API_KEYS.VIEWPLAN,
      apiData: data
    };

    return this._api.postApi(apiObj).
      pipe(map((response: any) => {
        return response;
      }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }
  editPlan(data) {
    const apiObj: IapiBaseObject = {
      tokenRequiredFlag: true,
      endpoint: API_KEYS.EDITPLAN,
      apiData: data
    };

    return this._api.postApi(apiObj).
      pipe(map((response: any) => {
        return response;
      }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }
  deletePlan(data) {
    const apiObj: IapiBaseObject = {
      tokenRequiredFlag: true,
      endpoint: API_KEYS.DELETEPLAN,
      apiData: data
    };

    return this._api.postApi(apiObj).
      pipe(map((response: any) => {
        return response;
      }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }
}

