import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../../services/auth.service';
@Component({
  selector: 'app-success-msg',
  templateUrl: './success-msg.component.html',
  styleUrls: ['./success-msg.component.css']
})
export class SuccessMsgComponent implements OnInit {
  email: any;
  isShow: boolean;
  successMsg: String;
  isLinkSent: Boolean = false;
  constructor(private route: Router, private authService: AuthService) {
    this.successMsg = "";
    this.isShow = false
  }

  ngOnInit(): void {
    this.email = localStorage.getItem('email')
    console.log(this.email)
  }
  resendLink() {
    if (!this.isLinkSent) {
      this.isLinkSent = true
      this.authService.forgotPassword({ email: this.email, type: 'Admin' }).subscribe(res => {
        console.log(res)
        this.isLinkSent = false
        this.isShow = true;
        this.successMsg = res.message
        //  this.route.navigate(['/adminUrl/success']);

      });
    }

    // this.route.navigate(['/adminUrl/resetPassword']); 
  }

  dismissModal(e): void {
    this.isShow = false;
  }
}
