import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthService} from '../../services/auth.service';
@Component({
  selector: 'app-dashboard',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.css']
})
export class ForgotPasswordComponent implements OnInit {

  forgotForm: FormGroup;
  isSubmitted: boolean;
  isShowError: boolean;
  errMsg: String;
  isShow : boolean;
  successMsg : String;
  constructor(private fb: FormBuilder, private route: Router, private authService: AuthService){
    this.isSubmitted = false;
    this.isShowError = false;
    this.isShow = false
    this.errMsg = "";
    this.successMsg = "";
   }

  ngOnInit(): void {
    this.formBuilder();
  }
  dismissModal(e): void {
    if (this.isShow) {
      this.isShow = false;
      this.route.navigate(['/adminUrl/success']);
    } else {
      this.isShowError = false;
    }
  }

  formBuilder(): void {
    this.forgotForm = this.fb.group({
      email: ['', Validators.required],
    });
  }
  submitForm(){
    this.isSubmitted =true; 
    let obj = {
      type:"Admin",
      email:this.forgotForm.value.email
    }
 if(this.forgotForm.status === 'VALID') {
  this.authService.forgotPassword(obj).subscribe(res => {
     console.log(res)
      JSON.stringify(localStorage.setItem('email',this.forgotForm.value.email)) 
      this.isSubmitted = false;
      this.isShow = true;
      this.successMsg = res.message
      //  this.route.navigate(['/adminUrl/success']);
         
   }, error => {
       console.log(error);
       this.isShowError = true;
       this.errMsg = error.error.message;
       setTimeout(() => {
         this.isShowError = false;
       }, 3000);
   });

 }
}
}
